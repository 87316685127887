/**
 * @generated SignedSource<<bb95e316cf0e1b67eab47e22361e0b9a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Permission = "ADMIN__MANAGE_ALL_CAMPAIGNS" | "ADMIN__MANAGE_ALL_ORGANIZATIONS" | "ADMIN__MANAGE_ALL_PROJECTS" | "APPROVAL_TOOL__VIEW_APPROVAL_TOOL_URL" | "CREATOR_PROFILES__EDIT" | "CREATOR_PROFILES__MODERATE" | "CREATOR_PROFILES__VIEW_CAMPAIGNS_HISTORY" | "LONGLISTS__COMMENTS_CREATED_HIDDEN_BY_DEFAULT" | "LONGLISTS__CREATE_CAMPAIGNS" | "LONGLISTS__EXPORT_LONGLISTS" | "LONGLISTS__LIST_CAMPAIGNS" | "LONGLISTS__RATE_CREATORS" | "LONGLISTS__UPDATE_LONGLISTS" | "LONGLISTS__VIEW_ACTIVE_AUDIENCE" | "LONGLISTS__VIEW_HIDDEN_COMMENTS" | "LONGLISTS__VIEW_LONGLISTS" | "LONGLISTS__VIEW_PRICES" | "PAYMENT__VIEW_TRANSACTIONS" | "REVENUE_TRANSACTIONS__MANAGE";
export type PlanId = "ADVANCED" | "ADVANCED_2021_10" | "ADVANCED_2022_11" | "AGENCY_2024_03" | "BARTER" | "BASIC_OLD_PS" | "BRAND_2024_03" | "CONTENT_CREATION" | "ENTERPRISE" | "FREE" | "FREE_OLD_PS" | "FREE_TRIAL" | "FULLY_MANAGED" | "FULLY_MANAGED_1" | "FULLY_MANAGED_2" | "FULLY_MANAGED_TTCX_MMA" | "GROW" | "PAYOUT" | "PREMIUM" | "PREMIUM_PLUS" | "PUBLICIS" | "SCALE" | "SCALE_2021_06" | "SCALE_2021_10" | "SMARTLY_ENTERPRISE_2023_06" | "SMARTLY_ENTERPRISE_TRIAL_2023_06" | "TRIAL_2022_08" | "TRIAL_2024_03" | "TRIAL_MONTH" | "UGC_2022_08" | "UGC_ADS_2022_08" | "WILDJAM";
export type AdminTransactionsContainerQuery$variables = Record<PropertyKey, never>;
export type AdminTransactionsContainerQuery$data = {
  readonly currentUser: {
    readonly permissions: ReadonlyArray<Permission>;
  } | null | undefined;
  readonly departments: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
  readonly planIds: ReadonlyArray<PlanId>;
};
export type AdminTransactionsContainerQuery = {
  response: AdminTransactionsContainerQuery$data;
  variables: AdminTransactionsContainerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "Department",
  "kind": "LinkedField",
  "name": "departments",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "permissions",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "planIds",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AdminTransactionsContainerQuery",
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      (v3/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AdminTransactionsContainerQuery",
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v0/*: any*/)
        ],
        "storageKey": null
      },
      (v3/*: any*/)
    ]
  },
  "params": {
    "cacheID": "610bc5841dc8949977d4999d5ef4e941",
    "id": null,
    "metadata": {},
    "name": "AdminTransactionsContainerQuery",
    "operationKind": "query",
    "text": "query AdminTransactionsContainerQuery {\n  departments {\n    id\n    name\n  }\n  currentUser {\n    permissions\n    id\n  }\n  planIds\n}\n"
  }
};
})();

(node as any).hash = "e11f6e598569f3f32bca88d1900fff3f";

export default node;

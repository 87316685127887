/**
 * @generated SignedSource<<c18818597464e42066e93aeb5c934e82>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Accounting_InvoiceStatus = "ISSUED" | "NOT_ISSUED" | "NOT_REQUIRED";
export type Accounting_PaymentMethodType = "INVOICE" | "MANUAL" | "PSP" | "PSP_INVOICE";
export type Accounting_PaymentStatus = "NOT_PAID" | "NOT_REQUIRED" | "PAID" | "REFUNDED";
export type Accounting_RevenueTransactionType = "ACTIVATION_FEE" | "CREATOR_EXPENSE" | "CUSTOM_SERVICE" | "DEPOSIT" | "FULLY_MANAGED_ZEROING" | "REFERRAL_REWARD" | "REFUND" | "SALES_PEAK_REWARD" | "SUBSCRIPTION";
export type AddonId = "BRANDS" | "SEATS" | "WHITELISTING_LICENSES";
export type Currency = "CAD" | "RUB" | "USD";
export type PlanId = "ADVANCED" | "ADVANCED_2021_10" | "ADVANCED_2022_11" | "AGENCY_2024_03" | "BARTER" | "BASIC_OLD_PS" | "BRAND_2024_03" | "CONTENT_CREATION" | "ENTERPRISE" | "FREE" | "FREE_OLD_PS" | "FREE_TRIAL" | "FULLY_MANAGED" | "FULLY_MANAGED_1" | "FULLY_MANAGED_2" | "FULLY_MANAGED_TTCX_MMA" | "GROW" | "PAYOUT" | "PREMIUM" | "PREMIUM_PLUS" | "PUBLICIS" | "SCALE" | "SCALE_2021_06" | "SCALE_2021_10" | "SMARTLY_ENTERPRISE_2023_06" | "SMARTLY_ENTERPRISE_TRIAL_2023_06" | "TRIAL_2022_08" | "TRIAL_2024_03" | "TRIAL_MONTH" | "UGC_2022_08" | "UGC_ADS_2022_08" | "WILDJAM";
export type SubscriptionStatusType = "ADDONS" | "DOWNGRADE" | "INITIAL" | "OTHER" | "RENEW" | "UPGRADE";
import { FragmentRefs } from "relay-runtime";
export type TransactionItem_revenueTransaction$data = {
  readonly author: {
    readonly advertiserProfile: {
      readonly firstName: string | null | undefined;
      readonly lastName: string | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly billingPeriodEndsAt: any | null | undefined;
  readonly billingPeriodStartsAt: any | null | undefined;
  readonly campaign: {
    readonly id: string;
    readonly name: string | null | undefined;
  } | null | undefined;
  readonly comment: string | null | undefined;
  readonly createdAt: any;
  readonly csmMarkup: number;
  readonly currency: Currency;
  readonly department: {
    readonly name: string;
  } | null | undefined;
  readonly id: string;
  readonly invoiceStatus: Accounting_InvoiceStatus;
  readonly netCost: number;
  readonly organization: {
    readonly id: string;
    readonly name: string;
  };
  readonly paidAt: any | null | undefined;
  readonly paymentMethodType: Accounting_PaymentMethodType;
  readonly paymentStatus: Accounting_PaymentStatus;
  readonly profit: number;
  readonly revenue: number;
  readonly subscriptionAddonItems: ReadonlyArray<{
    readonly addon: {
      readonly id: AddonId;
    };
    readonly quantity: number;
  }> | null | undefined;
  readonly subscriptionPlanId: PlanId | null | undefined;
  readonly subscriptionStatusType: SubscriptionStatusType | null | undefined;
  readonly type: Accounting_RevenueTransactionType | null | undefined;
  readonly verifiedByAdmin: boolean;
  readonly " $fragmentType": "TransactionItem_revenueTransaction";
};
export type TransactionItem_revenueTransaction$key = {
  readonly " $data"?: TransactionItem_revenueTransaction$data;
  readonly " $fragmentSpreads": FragmentRefs<"TransactionItem_revenueTransaction">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TransactionItem_revenueTransaction",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "paidAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "paymentMethodType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "author",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AdvertiserProfile",
          "kind": "LinkedField",
          "name": "advertiserProfile",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "firstName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastName",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Department",
      "kind": "LinkedField",
      "name": "department",
      "plural": false,
      "selections": [
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Campaign",
      "kind": "LinkedField",
      "name": "campaign",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "billingPeriodEndsAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "billingPeriodStartsAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subscriptionStatusType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AddonItem",
      "kind": "LinkedField",
      "name": "subscriptionAddonItems",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Addon",
          "kind": "LinkedField",
          "name": "addon",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "quantity",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subscriptionPlanId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "netCost",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "csmMarkup",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "profit",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "revenue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "paymentStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "invoiceStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "verifiedByAdmin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "comment",
      "storageKey": null
    }
  ],
  "type": "Accounting_RevenueTransaction",
  "abstractKey": null
};
})();

(node as any).hash = "59876b13a3d01cbb304207678675bfd0";

export default node;

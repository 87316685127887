import React from 'react';

import updateTransaction from 'Mutations/accounting/UpdateTransaction.Mutation';
import TextButton from 'Atoms/TextButton/TextButton';
import Icon from 'Components/ui/Icon/Icon';

interface Props {
  revenueTransactionId: string;
  verifiedByAdmin: boolean;
}

const VerifiedStatus: React.FC<Props> = (props) => {
  const { revenueTransactionId, verifiedByAdmin } = props;

  const handleClick = () => {
    updateTransaction({ revenueTransactionId, verifiedByAdmin: !verifiedByAdmin });
  };

  const color = verifiedByAdmin ? 'secondary' : 'normal';

  return (
    <TextButton
      color={color}
      icon={<Icon name="Check" />}
      onClick={handleClick}
      data-test="verifiedStatus:textButton:unknown"
    />
  );
};

export default VerifiedStatus;

/**
 * @generated SignedSource<<285334f6ca793a3ea7870800cf8ee697>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Accounting_PaymentMethodType = "INVOICE" | "MANUAL" | "PSP" | "PSP_INVOICE";
export type Accounting_PaymentStatus = "NOT_PAID" | "NOT_REQUIRED" | "PAID" | "REFUNDED";
export type Accounting_RevenueTransactionType = "ACTIVATION_FEE" | "CREATOR_EXPENSE" | "CUSTOM_SERVICE" | "DEPOSIT" | "FULLY_MANAGED_ZEROING" | "REFERRAL_REWARD" | "REFUND" | "SALES_PEAK_REWARD" | "SUBSCRIPTION";
export type SubscriptionStatusType = "ADDONS" | "DOWNGRADE" | "INITIAL" | "OTHER" | "RENEW" | "UPGRADE";
export type RevenueTransactionPaymentMethodFilter = {
  departmentIds?: ReadonlyArray<string> | null | undefined;
  paymentMethodType?: Accounting_PaymentMethodType | null | undefined;
};
export type AdminTransactionsQuery$variables = {
  dateFrom?: any | null | undefined;
  dateTo?: any | null | undefined;
  organizationIds?: ReadonlyArray<string> | null | undefined;
  paidDateFrom?: any | null | undefined;
  paidDateTo?: any | null | undefined;
  paymentMethodFilters?: ReadonlyArray<RevenueTransactionPaymentMethodFilter> | null | undefined;
  paymentStatus?: Accounting_PaymentStatus | null | undefined;
  subscriptionStatusType?: SubscriptionStatusType | null | undefined;
  type?: Accounting_RevenueTransactionType | null | undefined;
};
export type AdminTransactionsQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"Transactions_transactions">;
};
export type AdminTransactionsQuery = {
  response: AdminTransactionsQuery$data;
  variables: AdminTransactionsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "dateFrom"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "dateTo"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationIds"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "paidDateFrom"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "paidDateTo"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "paymentMethodFilters"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "paymentStatus"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "subscriptionStatusType"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "type"
},
v9 = {
  "kind": "Variable",
  "name": "dateFrom",
  "variableName": "dateFrom"
},
v10 = {
  "kind": "Variable",
  "name": "dateTo",
  "variableName": "dateTo"
},
v11 = {
  "kind": "Variable",
  "name": "organizationIds",
  "variableName": "organizationIds"
},
v12 = {
  "kind": "Variable",
  "name": "paidDateFrom",
  "variableName": "paidDateFrom"
},
v13 = {
  "kind": "Variable",
  "name": "paidDateTo",
  "variableName": "paidDateTo"
},
v14 = {
  "kind": "Variable",
  "name": "paymentMethodFilters",
  "variableName": "paymentMethodFilters"
},
v15 = {
  "kind": "Variable",
  "name": "paymentStatus",
  "variableName": "paymentStatus"
},
v16 = {
  "kind": "Variable",
  "name": "subscriptionStatusType",
  "variableName": "subscriptionStatusType"
},
v17 = {
  "kind": "Variable",
  "name": "type",
  "variableName": "type"
},
v18 = [
  {
    "kind": "Literal",
    "name": "currencies",
    "value": [
      "USD"
    ]
  },
  (v9/*: any*/),
  (v10/*: any*/),
  {
    "kind": "Literal",
    "name": "first",
    "value": 15
  },
  (v11/*: any*/),
  (v12/*: any*/),
  (v13/*: any*/),
  (v14/*: any*/),
  (v15/*: any*/),
  (v16/*: any*/),
  (v17/*: any*/)
],
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v21 = [
  (v19/*: any*/),
  (v20/*: any*/)
],
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "netCost",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profit",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "revenue",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AdminTransactionsQuery",
    "selections": [
      {
        "args": [
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "Transactions_transactions"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v8/*: any*/),
      (v2/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Operation",
    "name": "AdminTransactionsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Accounting_Query",
        "kind": "LinkedField",
        "name": "accounting",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v18/*: any*/),
            "concreteType": "Accounting_RevenueTransactionConnection",
            "kind": "LinkedField",
            "name": "revenueTransactions",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Accounting_RevenueTransactionEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Accounting_RevenueTransaction",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v19/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "createdAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "paidAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "paymentMethodType",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "author",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AdvertiserProfile",
                            "kind": "LinkedField",
                            "name": "advertiserProfile",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "firstName",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "lastName",
                                "storageKey": null
                              },
                              (v19/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v19/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Department",
                        "kind": "LinkedField",
                        "name": "department",
                        "plural": false,
                        "selections": [
                          (v20/*: any*/),
                          (v19/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Organization",
                        "kind": "LinkedField",
                        "name": "organization",
                        "plural": false,
                        "selections": (v21/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Campaign",
                        "kind": "LinkedField",
                        "name": "campaign",
                        "plural": false,
                        "selections": (v21/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "billingPeriodEndsAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "billingPeriodStartsAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "subscriptionStatusType",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AddonItem",
                        "kind": "LinkedField",
                        "name": "subscriptionAddonItems",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Addon",
                            "kind": "LinkedField",
                            "name": "addon",
                            "plural": false,
                            "selections": [
                              (v19/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "quantity",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "subscriptionPlanId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "type",
                        "storageKey": null
                      },
                      (v22/*: any*/),
                      (v23/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "csmMarkup",
                        "storageKey": null
                      },
                      (v24/*: any*/),
                      (v25/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "paymentStatus",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "invoiceStatus",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "verifiedByAdmin",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "comment",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Accounting_RevenueTransactionSummary",
                "kind": "LinkedField",
                "name": "summary",
                "plural": false,
                "selections": [
                  (v23/*: any*/),
                  (v24/*: any*/),
                  (v25/*: any*/),
                  (v22/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v18/*: any*/),
            "filters": [],
            "handle": "connection",
            "key": "Admin_revenueTransactions",
            "kind": "LinkedHandle",
            "name": "revenueTransactions"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e35a9f1c1f8317ee3306e4cd49e81c0c",
    "id": null,
    "metadata": {},
    "name": "AdminTransactionsQuery",
    "operationKind": "query",
    "text": "query AdminTransactionsQuery(\n  $dateFrom: DateTime\n  $dateTo: DateTime\n  $paidDateFrom: DateTime\n  $paidDateTo: DateTime\n  $paymentMethodFilters: [RevenueTransactionPaymentMethodFilter!]\n  $paymentStatus: Accounting_PaymentStatus\n  $type: Accounting_RevenueTransactionType\n  $organizationIds: [ID!]\n  $subscriptionStatusType: SubscriptionStatusType\n) {\n  ...Transactions_transactions_o6q5v\n}\n\nfragment TransactionItem_revenueTransaction on Accounting_RevenueTransaction {\n  id\n  createdAt\n  paidAt\n  paymentMethodType\n  author {\n    advertiserProfile {\n      firstName\n      lastName\n      id\n    }\n    id\n  }\n  department {\n    name\n    id\n  }\n  organization {\n    id\n    name\n  }\n  campaign {\n    id\n    name\n  }\n  billingPeriodEndsAt\n  billingPeriodStartsAt\n  subscriptionStatusType\n  subscriptionAddonItems {\n    addon {\n      id\n    }\n    quantity\n  }\n  subscriptionPlanId\n  type\n  currency\n  netCost\n  csmMarkup\n  profit\n  revenue\n  paymentStatus\n  invoiceStatus\n  verifiedByAdmin\n  comment\n}\n\nfragment TransactionSummary_summary on Accounting_RevenueTransactionSummary {\n  netCost\n  profit\n  revenue\n  currency\n}\n\nfragment Transactions_transactions_o6q5v on Query {\n  accounting {\n    revenueTransactions(first: 15, dateFrom: $dateFrom, dateTo: $dateTo, paidDateFrom: $paidDateFrom, paidDateTo: $paidDateTo, paymentMethodFilters: $paymentMethodFilters, paymentStatus: $paymentStatus, type: $type, currencies: [USD], organizationIds: $organizationIds, subscriptionStatusType: $subscriptionStatusType) {\n      edges {\n        node {\n          id\n          ...TransactionItem_revenueTransaction\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      summary {\n        netCost\n        profit\n        revenue\n        ...TransactionSummary_summary\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ba2909310021a78baa724cbc279e79b4";

export default node;

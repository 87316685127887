import React from 'react';
import { graphql, PreloadedQuery, usePaginationFragment, usePreloadedQuery } from 'react-relay';

import { AdminTransactionsQuery } from '../AdminTransactions';

import styles from './Transactions.pcss';
import TransactionSummary from './TransactionSummary/TransactionSummary';
import TransactionsBar from './TransactionsBar/TransactionsBar';
import TransactionItem from './TransactionItem/TransactionItem';

import LoaderHandlerWithHooks from 'Organisms/LoaderHandler/NewLoaderHandler';
import { AdminTransactionsQuery as QueryType } from 'GraphTypes/AdminTransactionsQuery.graphql';
import { Transactions_transactions$key } from 'GraphTypes/Transactions_transactions.graphql';
import { AdminTransactionsContainerQuery$data } from 'GraphTypes/AdminTransactionsContainerQuery.graphql';

const OFFSET = 70;
const COUNT = 20;

interface Props {
  planIds?: AdminTransactionsContainerQuery$data['planIds'];
  queryReference: PreloadedQuery<QueryType>;
}

const Transactions: React.FC<Props> = (props) => {
  const { queryReference, planIds } = props;
  const queryData = usePreloadedQuery<QueryType>(AdminTransactionsQuery, queryReference);

  const fragmentData = usePaginationFragment<QueryType, Transactions_transactions$key>(
    graphql`
      fragment Transactions_transactions on Query
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 15 }
        cursor: { type: "String" }
        dateFrom: { type: "DateTime" }
        dateTo: { type: "DateTime" }
        paidDateFrom: { type: "DateTime" }
        paidDateTo: { type: "DateTime" }
        paymentMethodFilters: { type: "[RevenueTransactionPaymentMethodFilter!]" }
        paymentStatus: { type: "Accounting_PaymentStatus" }
        type: { type: "Accounting_RevenueTransactionType" }
        organizationIds: { type: "[ID!]" }
        subscriptionStatusType: { type: "SubscriptionStatusType" }
      )
      @refetchable(queryName: "TransactionsListPaginationList") {
        accounting {
          revenueTransactions(
            first: $count
            after: $cursor
            dateFrom: $dateFrom
            dateTo: $dateTo
            paidDateFrom: $paidDateFrom
            paidDateTo: $paidDateTo
            paymentMethodFilters: $paymentMethodFilters
            paymentStatus: $paymentStatus
            type: $type
            currencies: [USD]
            organizationIds: $organizationIds
            subscriptionStatusType: $subscriptionStatusType
          ) @connection(key: "Admin_revenueTransactions", filters: []) {
            edges {
              node {
                id
                ...TransactionItem_revenueTransaction
              }
            }
            pageInfo {
              hasNextPage
              endCursor
            }
            summary {
              netCost
              profit
              revenue
              ...TransactionSummary_summary
            }
          }
        }
      }
    `,
    queryData
  );

  const items = Array.from(fragmentData.data.accounting?.revenueTransactions?.edges || []);

  const summary = fragmentData.data.accounting?.revenueTransactions?.summary;
  const netCost = summary?.netCost;
  const profit = summary?.profit;
  const revenue = summary?.revenue;

  return (
    <div className={styles.list}>
      <LoaderHandlerWithHooks relay={fragmentData} offset={OFFSET} count={COUNT} items={items}>
        {items.length > 0 && <TransactionsBar />}
        {items.map((item) => {
          if (!item?.node) return null;

          return <TransactionItem key={item.node.id} transaction={item.node} planIds={planIds} />;
        })}
        {!!(netCost || profit || revenue) && <TransactionSummary summary={summary} />}
      </LoaderHandlerWithHooks>
    </div>
  );
};

export default Transactions;

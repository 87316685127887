import React from 'react';
import { graphql, useFragment } from 'react-relay';

import styles from './TransactionSummary.pcss';

import { formatValue } from 'Util/dataFormatter';
import Text from 'Components/ui/Text/Text';
import { TransactionSummary_summary$key } from 'GraphTypes/TransactionSummary_summary.graphql';

interface Props {
  summary: TransactionSummary_summary$key;
}

const TransactionSummary = (props: Props) => {
  const { summary } = props;

  const data = useFragment(
    graphql`
      fragment TransactionSummary_summary on Accounting_RevenueTransactionSummary {
        netCost
        profit
        revenue
        currency
      }
    `,
    summary
  );
  const { netCost, profit, revenue, currency } = data;

  const createValue = (title: string, value?: number | null) => {
    const formattedValue = formatValue({ value, currency, format: 'currency' });

    return (
      <div className={styles.value}>
        <Text
          type="s"
          color="grey"
          msg={`admin_transactions.total_${title}`}
          data-test="transactionSummary:text:unknown"
        />
        <Text
          weight="bold"
          text={formattedValue}
          className={styles.valueText}
          data-test="transactionSummary:text:valueText"
        />
      </div>
    );
  };

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <Text
          weight="bold"
          msg="admin_transactions.total_title"
          data-test="transactionSummary:text:totalTitle"
        />
        <div className={styles.values}>
          {createValue('net_cost', netCost)}
          {createValue('profit', profit)}
          {createValue('revenue', revenue)}
        </div>
      </div>
    </div>
  );
};

export default TransactionSummary;

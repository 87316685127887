import React from 'react';

import styles from './TransactionsBar.pcss';

import Text from 'Components/ui/Text/Text';

const TransactionsBar = () => {
  return (
    <div className={styles.root}>
      <Text msg="bar.date" />
      <Text msg="bar.method" />
      <Text msg="bar.advertiser" />
      <Text msg="bar.type" />
      <Text msg="bar.currency" />
      <Text msg="bar.net_cost" />
      <Text msg="bar.transacion_fee" />
      <Text msg="bar.revenue" />
      <Text msg="bar.profit" />
      <Text msg="bar.payment_status" />
      <Text msg="bar.subscriptionStatusType" />
    </div>
  );
};

export default TransactionsBar;

import React, { useState, useMemo, Suspense, useEffect } from 'react';
import { graphql, useQueryLoader } from 'react-relay';
import track from 'react-tracking';

import styles from './AdminTransactions.pcss';
import Transactions from './Transactions/Transactions';
import AdminTransactionsFilters from './AdminTransactionsFilters/AdminTransactionsFilters';

import { createStartOfNewYorkDay, createEndOfNewYorkDay } from 'Util/dateCreator';
import Page from 'Templates/Page/Page';
import Text from 'Components/ui/Text/Text';
import { MANUAL_PAYMENT_METHOD, USD } from 'Constants/general';
import { AdminTransactionsQuery as QueryType } from 'GraphTypes/AdminTransactionsQuery.graphql';
import { AdminTransactionsContainerQuery$data } from 'GraphTypes/AdminTransactionsContainerQuery.graphql';
import Spinner from 'Atoms/Spinner/Spinner';

export const AdminTransactionsQuery = graphql`
  query AdminTransactionsQuery(
    $dateFrom: DateTime
    $dateTo: DateTime
    $paidDateFrom: DateTime
    $paidDateTo: DateTime
    $paymentMethodFilters: [RevenueTransactionPaymentMethodFilter!]
    $paymentStatus: Accounting_PaymentStatus
    $type: Accounting_RevenueTransactionType
    $organizationIds: [ID!]
    $subscriptionStatusType: SubscriptionStatusType
  ) {
    ...Transactions_transactions
      @arguments(
        dateFrom: $dateFrom
        dateTo: $dateTo
        paidDateFrom: $paidDateFrom
        paidDateTo: $paidDateTo
        paymentMethodFilters: $paymentMethodFilters
        paymentStatus: $paymentStatus
        type: $type
        organizationIds: $organizationIds
        subscriptionStatusType: $subscriptionStatusType
      )
  }
`;

export const defaultState = {
  dateFrom: null,
  dateTo: null,
  paidDateFrom: null,
  paidDateTo: null,
  paymentMethodType: [],
  paymentMethodFilters: [],
  paymentStatus: null,
  organizationIds: [],
  organizations: [],
  departments: [],
  type: null,
  subscriptionStatusType: null,
};

interface Props {
  data: AdminTransactionsContainerQuery$data;
}

const AdminTransactions: React.FC<Props> = (props) => {
  const { data } = props;
  const planIds = data.planIds;

  const departments = data.departments.map((item) => {
    return {
      id: item.id,
      name: item.name,
    };
  });

  const [queryReference, loadQuery] = useQueryLoader<QueryType>(AdminTransactionsQuery);

  const [filters, setFilters] = useState(defaultState);

  const formattedParams = useMemo(() => {
    const {
      organizationIds: organizationIdsValue,
      paymentMethodFilters: paymentMethodFiltersValue,
      paymentStatus,
      type,
      dateTo,
      dateFrom,
      paidDateTo,
      paidDateFrom,
      departments,
      subscriptionStatusType,
    } = filters;
    const organizationIds = organizationIdsValue;
    const paymentStatusValue = paymentStatus;
    const dateToValue = dateTo ? createEndOfNewYorkDay(dateTo, USD) : null;
    const dateFromValue = dateFrom ? createStartOfNewYorkDay(dateFrom, USD) : null;
    const paidDateToValue = paidDateTo ? createEndOfNewYorkDay(paidDateTo, USD) : null;
    const paidDateFromValue = paidDateFrom ? createStartOfNewYorkDay(paidDateFrom, USD) : null;
    const paymentMethodFilters = paymentMethodFiltersValue.map((item) => ({
      paymentMethodType: item,
      departmentIds:
        item === MANUAL_PAYMENT_METHOD && departments?.length ? departments : undefined,
    }));

    return {
      ...filters,
      paymentMethodFilters,
      organizationIds,
      paymentStatus: paymentStatusValue,
      type,
      dateTo: dateToValue,
      dateFrom: dateFromValue,
      paidDateTo: paidDateToValue,
      paidDateFrom: paidDateFromValue,
      subscriptionStatusType,
    };
  }, [filters]);

  useEffect(() => {
    loadQuery(
      { ...formattedParams },
      {
        fetchPolicy: 'network-only',
      }
    );
  }, [formattedParams]);

  const handleFiltersChange = (newFilters) => {
    setFilters({ ...filters, ...newFilters });
  };

  return (
    <Page className={styles.root}>
      <div className={styles.container}>
        <Text
          type="d2"
          msg="admin_transactions.title"
          className={styles.title}
          data-test="adminTransactions:text:title"
        />
        <AdminTransactionsFilters
          departments={departments}
          filters={filters}
          onFiltersChange={handleFiltersChange}
        />
        <Suspense fallback={<Spinner className={styles.preloader} />}>
          {queryReference && (
            <Transactions {...formattedParams} queryReference={queryReference} planIds={planIds} />
          )}
        </Suspense>
      </div>
    </Page>
  );
};

export default track(
  {
    page: 'admin_transactions',
  },
  { dispatchOnMount: true }
)(AdminTransactions);

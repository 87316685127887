import React, { useEffect } from 'react';
import { useLazyLoadQuery, graphql } from 'react-relay';
import { useNavigate } from 'react-router-dom';

import AdminTransactions from './AdminTransactions';

import { AdminTransactionsContainerQuery as QueryType } from 'GraphTypes/AdminTransactionsContainerQuery.graphql';
import { ERROR_ROUTE } from 'Constants/general';

const AdminTransactionsContainerQuery = graphql`
  query AdminTransactionsContainerQuery {
    departments {
      id
      name
    }
    currentUser {
      permissions
    }
    planIds
  }
`;

const AdminTransactionsContainer = () => {
  const data = useLazyLoadQuery<QueryType>(AdminTransactionsContainerQuery, {});

  const navigate = useNavigate();

  if (!data) return null;

  const permissions = data.currentUser?.permissions || [];

  useEffect(() => {
    if (!permissions.includes('REVENUE_TRANSACTIONS__MANAGE')) {
      navigate(ERROR_ROUTE);
    }
  }, [permissions]);

  return <AdminTransactions data={data} />;
};

export default AdminTransactionsContainer;

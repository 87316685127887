import React from 'react';
import { graphql, useFragment } from 'react-relay';
import classnames from 'classnames';
import classNames from 'classnames';

import TransactionType from '../TransactionType/TransactionType';
import PaymentStatus from '../PaymentStatus/PaymentStatus';
import VerifiedStatus from '../VerifiedStatus/VerifiedStatus';

import styles from './TransactionItem.pcss';

import { createNumber, createPercent } from 'Util/numberFormatter';
import { ADMIN_ORGANIZATIONS_ROUTE } from 'Constants/general';
import { dateStringToNewYorkDate } from 'Util/dateCreator';
import Text from 'Components/ui/Text/Text';
import TextButton from 'Components/ui/TextButton/TextButton';
import SmartLink from 'Atoms/SmartLink/SmartLink';
import { TransactionItem_revenueTransaction$key } from 'GraphTypes/TransactionItem_revenueTransaction.graphql';
import { PlanId } from 'GraphTypes/AdminTransactionsContainerQuery.graphql';

interface Props {
  planIds?: PlanId[];
  transaction: TransactionItem_revenueTransaction$key;
}

const TransactionItem: React.FC<Props> = (props) => {
  const { transaction, planIds } = props;

  const data = useFragment(
    graphql`
      fragment TransactionItem_revenueTransaction on Accounting_RevenueTransaction {
        id
        createdAt
        paidAt
        paymentMethodType
        author {
          advertiserProfile {
            firstName
            lastName
          }
        }
        department {
          name
        }
        organization {
          id
          name
        }
        campaign {
          id
          name
        }
        billingPeriodEndsAt
        billingPeriodStartsAt
        subscriptionStatusType
        subscriptionAddonItems {
          addon {
            id
          }
          quantity
        }
        subscriptionPlanId
        type
        currency
        netCost
        csmMarkup
        profit
        revenue
        paymentStatus
        invoiceStatus
        verifiedByAdmin
        comment
      }
    `,
    transaction
  );

  const id = data.id;
  const createdAt = data.createdAt;
  const paidAt = data.paidAt;
  const transactionType = data.type;
  const department = data.department;
  const currency = data.currency;
  const netCost = data.netCost;
  const profit = data.profit;
  const revenue = data.revenue;
  const paymentStatus = data.paymentStatus;
  const subscriptionStatusType = data.subscriptionStatusType?.toLowerCase();
  const subscriptionPlanId = data.subscriptionPlanId;
  const subscriptionAddonItems = data.subscriptionAddonItems;
  const billingPeriodEndsAt = data.billingPeriodEndsAt;
  const billingPeriodStartsAt = data.billingPeriodStartsAt;
  const organization = data.organization;
  const verifiedByAdmin = data.verifiedByAdmin;
  const paymentMethodType = data.paymentMethodType;
  const date = dateStringToNewYorkDate(createdAt);

  const paymentMethodTypeMsgNs = 'search_section.form.payment_method';
  const paymentMethodTypeMsg = `${paymentMethodTypeMsgNs}_${paymentMethodType.toLowerCase()}`;

  const advertiserName = organization.name;
  const advertiserLink = `${ADMIN_ORGANIZATIONS_ROUTE}${organization.id}`;

  const authorName = department && department.name;

  const transactionFee =
    transactionType !== 'DEPOSIT' || profit === 0 || netCost === 0
      ? '—'
      : createPercent(profit / netCost);

  const needToFillDates =
    transactionType === 'SUBSCRIPTION' &&
    paymentMethodType === 'PSP_INVOICE' &&
    (!billingPeriodStartsAt || !billingPeriodEndsAt);

  const failed = !!(paymentStatus === 'REFUNDED' || needToFillDates);

  return (
    <div className={classnames(styles.root, { [styles.failed]: failed })}>
      <Text text={date} className={styles.cuttedText} />
      <div>
        <Text msg={paymentMethodTypeMsg} className={styles.cuttedText} />
        {authorName && (
          <Text type="s" color="grey" text={authorName} className={styles.cuttedText} />
        )}
      </div>
      <SmartLink path={advertiserLink} target="_blank">
        <TextButton
          text={advertiserName}
          color="purple"
          className={styles.link}
          textClassName={styles.cuttedText}
        />
      </SmartLink>
      <TransactionType
        revenueTransactionId={id}
        transactionType={transactionType}
        paymentMethodType={paymentMethodType}
        subscriptionPlanId={subscriptionPlanId}
        subscriptionAddonItems={subscriptionAddonItems}
        planIds={planIds}
        billingPeriodEndsAt={billingPeriodEndsAt}
        billingPeriodStartsAt={billingPeriodStartsAt}
      />
      <Text text={currency} />
      <Text text={`${netCost}`} />
      <Text text={transactionFee} />
      <Text text={createNumber(profit)} />
      <Text text={createNumber(revenue)} />
      <PaymentStatus transactionId={id} status={paymentStatus} paidAt={paidAt} />
      {subscriptionStatusType ? (
        <Text msg={`admin_transactions.subscription_status_type.${subscriptionStatusType}`} />
      ) : (
        <Text text="—" />
      )}
      <div className={classNames(styles.verifyBtn, { [styles.isVerified]: !!verifiedByAdmin })}>
        <VerifiedStatus revenueTransactionId={id} verifiedByAdmin={verifiedByAdmin} />
      </div>
    </div>
  );
};

export default TransactionItem;
